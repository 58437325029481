import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as fromContactInfo from 'src/app/store/feature-stores/contact-info-store';
import { ActivatedRoute } from '@angular/router';
import { FirmType, JurisdictionCode, ContinueBtnHoverTxt, RegistrationPaths, EnvironmentSettings, OwnerOrShareholderTypeCode} from './common-constants';
import { AccountType } from 'src/app/store/models/internet-registration';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class CommonFunctions {
    constructor() {

     }
    public continueButtonHoverText;

    formatPhoneNumber(val: string) {
        if ( val === null || val.length !== 10 ) {
            throw new Error('Phone number value passed in is null or not 10 characters');
        }
        return val.substring(0, 3) + '.' + val.substring(3, 6) + '.' + val.substring(6, 10);
    }

    formatAddressType(value: string) {
        switch (value) {
            case 'ab': {
                return 'Accountant\'s or bookkeeper\'s office';
            }
            case 'r': {
                return 'Residence';
            }
            case 'al': {
                return 'Alternate Location';
            }
            case 'ho': {
                return 'Head office';
            }
        }
    }

    formatAddressTypeForSpouse(locationTypeCode: string) {
        let value = '';
        switch (locationTypeCode) {
            case 'ab':
                value = 'Accountant or bookkeeper\'s office';
                break;
            case 'al':
                value = 'Alternate location';
                break;
            case 'ho':
                value = 'Head office';
                break;
            case 'r':
                value = 'Residence';
                break;
            default:
                break;
        }
        return value;
    }

    formatYesNo(value: string) {
        switch (value) {
            case 'n':
            case 'N': {
                return 'No';
            }
            case 'y':
            case 'Y': {
                return 'Yes';
            }
        }
    }

    formatContactRelation(value: string) {
        switch (value) {
            case 'a': {
                return 'Accountant';
            }
            case 'fm': {
                return 'Family member';
            }
            case 'l': {
                return 'Lawyer';
            }
            case 's': {
                return 'Spouse';
            }
            case 'o': {
                return 'Other';
            }
            case 'self': {
                return 'Self';
            }
            case 'ce': {
                return 'Company employee';
            }
            case 'prop': {
                return 'I am the proprietor';
            }
            case 'p': {
                return 'I am a partner';
            }
            case 'Partner': {
                return 'I am a partner';
            }
            case 'sh': {
                return 'Shareholder';
            }
            default: {
                return '';
            }
        }
    }

    formatTitle(value: string) {
        switch (value) {
            case 'MR': {
                return 'Mr.';
            }
            case 'MRS': {
                return 'Mrs.';
            }
            case 'MIS': {
                return 'Miss';
            }
            case 'MS': {
                return 'Ms.';
            }
            case 'DR': {
                return 'Dr.';
            }
        }
    }

    formatFirmType(firmType: FirmType) {
        switch (firmType) {
            case FirmType.limitedCompany:
                return 'Limited Company';
            case FirmType.association:
                return 'Association';
            case FirmType.band:
                return 'Band (First Nations)';
            case FirmType.church:
                return 'Religious organization';
            case FirmType.cooperative:
                return 'Co-operative';
            case FirmType.society:
                return 'Society';
            case FirmType.union:
                return 'Union';
        }
    }

    formatJurisdiction(jurisdiction: JurisdictionCode) {
        switch (jurisdiction) {
            case JurisdictionCode.federal:
                return 'Federal';
            case JurisdictionCode.provincial:
                return 'Provincial';
            case JurisdictionCode.foreign:
                return 'Foreign';
        }
    }

    
    formatJurisdictionV2(jurisdiction: any) {
        switch (jurisdiction) {
            case JurisdictionCode.federal:
                return 'Federal';          
            case JurisdictionCode.foreign:
                return 'International';
            case JurisdictionCode.provincial:
                return 'Provincial';
            default: 
                return jurisdiction;
        }
    }

    formatCountryCode(value: string) {
        switch (value) {
            case 'US' : return 'United States';
            case 'CA' : return 'Canada';
            default: return '';
        }
    }

    isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }

    isEven(n) {
        n = Number(n);
        return n === 0 || !!(n && !(n % 2));
    }

    getCountryFullName(code: string, countries: any[]) {
        let displayName = '';
        const found = countries.find(element => element.code === code);
        if (found) {
            displayName = found.display;
        }
        return displayName;
    }

    convertToUpperCaseForFirstLetter(value: string) {
        let result = value;
        if (value != null && value.length > 0) {
          result = value[0].toLocaleUpperCase();
          if (value.length >= 1) {
            result += value.slice(1);
          }
        }
        return result;
      }
    formatHoursOfEmployment(workingHoursPerWeek: string): string {
        switch (workingHoursPerWeek) {
            case 'less24':
                return 'Less than 24 hours per week';
            case 'more24':
                return '24 hours or more';
            case 'less15':
                return 'Less than 15 hours per week';
            case 'more15':
                return 'More than 15 hours per week';
            case 'less8':
                return 'Less than 8 hours per week';
            case 'more8':
                return 'More than 8 hours per week';
            default:
                return '';
        }
    }

    mouseOverTextForDisabledForm(fg: UntypedFormGroup) {
        if (fg.valid) {
            this.continueButtonHoverText = '';
        } else {
            this.continueButtonHoverText = ContinueBtnHoverTxt;
        }
    }

    // Sample example url - http://www.worksafebc.com/-/media/WorksafeBC/Classifications/2019/2019_721027 
    openClassificationPDF(classificationUnitCode: number) {
        const currentYear = (new Date()).getFullYear();
        const concatenateYearClassificationId = currentYear + '_' + classificationUnitCode;
        const url = `${environment.classificationPDFUrl}${currentYear}/${concatenateYearClassificationId}`;
        window.open(url, '_blank');
    }

    trimLeadingAndTrailingSpace(value: string) {
        return value = value ? value.trim() : '';
    }

    copyOldIRegMailingAddressToNew(OldIRegMailingAddress, IRegMailingAddress) {
        IRegMailingAddress.addressLine1 = OldIRegMailingAddress.streetLine1;
        IRegMailingAddress.addressLine2 = OldIRegMailingAddress.streetLine2;
        IRegMailingAddress.country = OldIRegMailingAddress.country;
        if ( IRegMailingAddress.country === 'CA' ) {
            IRegMailingAddress.province = OldIRegMailingAddress.provinceCode;
        } else {
            IRegMailingAddress.state = OldIRegMailingAddress.provinceCode;
        }
        IRegMailingAddress.city = OldIRegMailingAddress.city;
        IRegMailingAddress.postalCode = OldIRegMailingAddress.postalCode;
        IRegMailingAddress.locationType = OldIRegMailingAddress.locationTypeCode;
        IRegMailingAddress.faxNumber = '';
        if (OldIRegMailingAddress.faxAreaCode && OldIRegMailingAddress.faxNumber) {
            IRegMailingAddress.faxNumber = OldIRegMailingAddress.faxAreaCode + OldIRegMailingAddress.faxNumber;
        } else {
            IRegMailingAddress.faxNumber = '';
        }
        IRegMailingAddress.phoneNumber = this.getEmptyStringForNull(OldIRegMailingAddress.phoneAreaCode) +
        this.getEmptyStringForNull(OldIRegMailingAddress.phoneNumber);
        IRegMailingAddress.phoneExtension = OldIRegMailingAddress.phoneExtension ? OldIRegMailingAddress.phoneExtension : '';
        IRegMailingAddress.email = OldIRegMailingAddress.email ? OldIRegMailingAddress.email : '';
        IRegMailingAddress.addressTypeCode = OldIRegMailingAddress.addressTypeCode;
    }

    copyNewMailingAddressToOldIreg(IRegMailingAddress, OldIRegMailingAddress) {
        OldIRegMailingAddress.streetLine1 = IRegMailingAddress.addressLine1;
        OldIRegMailingAddress.streetLine2 = IRegMailingAddress.addressLine2;
        OldIRegMailingAddress.country = IRegMailingAddress.country;
        if (IRegMailingAddress.country === 'CA') {
            OldIRegMailingAddress.provinceCode = IRegMailingAddress.province;
        } else {
            OldIRegMailingAddress.provinceCode = IRegMailingAddress.state;
        }
        OldIRegMailingAddress.city = IRegMailingAddress.city;
        OldIRegMailingAddress.postalCode = IRegMailingAddress.postalCode;
        OldIRegMailingAddress.locationTypeCode = IRegMailingAddress.locationType;
        OldIRegMailingAddress.faxAreaCode = IRegMailingAddress.faxNumber ? IRegMailingAddress.faxNumber.toString().slice(0, 3) : '';
        OldIRegMailingAddress.faxNumber = IRegMailingAddress.faxNumber ? IRegMailingAddress.faxNumber.toString().slice(3, 10) : '';
        OldIRegMailingAddress.phoneAreaCode = IRegMailingAddress.phoneNumber ? IRegMailingAddress.phoneNumber.toString().slice(0, 3): '';
        OldIRegMailingAddress.phoneNumber = IRegMailingAddress.phoneNumber ? IRegMailingAddress.phoneNumber.toString().slice(3, 10): '';
        OldIRegMailingAddress.phoneExtension = IRegMailingAddress.phoneExtension;
        OldIRegMailingAddress.email = IRegMailingAddress.email;
        OldIRegMailingAddress.sequenceNumber = IRegMailingAddress.sequenceNumber ? IRegMailingAddress.sequenceNumber : '1';
        OldIRegMailingAddress.isSamePhysicalAddress = IRegMailingAddress.isSamePhysicalAddress;
        OldIRegMailingAddress.addressTypeCode = IRegMailingAddress.addressTypeCode;
    }

    // Used in homeowner - does not copy phonenumber / areacode
    copyMailingAddress(source, target) {
        target.streetLine1 = source.streetLine1;
        target.streetLine2 = source.streetLine2;
        target.country = source.country;
        target.provinceCode = source.provinceCode;
        target.postalCode = source.postalCode;
        target.city = source.city;
        target.locationTypeCode = source.locationTypeCode;
        target.addressTypeCode = source.addressTypeCode;
        target.isSamePhysicalAddress = source.isSamePhysicalAddress;
        target.sequenceNumber = source.sequenceNumber;
        target.faxAreaCode = source.faxAreaCode;
        target.faxNumber = source.faxNumber;
    }

    // Full copy of mailing address - include phonenumber excluding isSamePhysicalAddress
    // This function is to be used to clone mailing address from p[0] to p[n] for any partner
    cloneMailingAddress(source, target) {
        target.streetLine1 = source.streetLine1;
        target.streetLine2 = source.streetLine2;
        target.country = source.country;
        target.provinceCode = source.provinceCode;
        target.postalCode = source.postalCode;
        target.city = source.city;
        target.locationTypeCode = source.locationTypeCode;
        target.addressTypeCode = source.addressTypeCode;
        target.sequenceNumber = source.sequenceNumber;
        target.faxAreaCode = source.faxAreaCode;
        target.faxNumber = source.faxNumber;
        target.phoneAreaCode = source.phoneAreaCode;
        target.phoneNumber = source.phoneNumber;
        target.phoneExtension = source.phoneExtension;
    }

    convertStringToNumber(value: string): number {
        try {            
            return parseFloat(value);
        } catch {}
        return 0;
    }

    checkDateIsBetweenMinAndMaxDate(date: Date, minDate: Date, maxDate: Date) {
        // eg. date in next year (2021) first quarter
        const timeDiffMinDay = date.getTime() - minDate.getTime();
        if ( timeDiffMinDay < 0 ) { return false; } // If the date is before minDate's date

        const timeDiffMaxDay = date.getTime() - maxDate.getTime();
        if ( timeDiffMaxDay > 0 ) { return false; } // If the date is after maxDate's date
        return true;
    }

    // Turns input1^input2^input3 -> input1, input2, input3
    expandCaratString(input: string): string {
        if ( input === null ) { return null; }

        const inputArray = input.split('^');
        let result = '';
        inputArray.forEach( (e) => {
          if ( e !== '' && e !== ' ' ) {
            result += ( result === '' ) ? e : ', ' + e;
          }
         });

        return ( result ===  '' ) ? null : result;
    }

    buildContactName(contactDetails: fromContactInfo.Model.ContactDetails): string {
        let name: string;
        let lFirstName: string;
        let lLastName: string;
        if (contactDetails.legalFirstName) {
            lFirstName = contactDetails.legalFirstName.trim();
        }
        if (contactDetails.legalLastName) {
            lLastName = contactDetails.legalLastName.trim();
        }
        if (contactDetails.legalMiddleName) {
          name = `${lFirstName} ${contactDetails.legalMiddleName} ${lLastName}`;
        } else {
          name = `${lFirstName} ${lLastName}`;
        }
        return name;
    }

   concatContactName(firstName: string, middleName: string, lastName: string): string {
        let name: string;
        if (middleName) {
        name = `${firstName} ${middleName} ${lastName}`;
        } else {
        name = `${firstName} ${lastName}`;
        }
        return name;
    }

    nullMapperHelper(property) {
        return ( property != null ) ? property : '';
    }

    /* Converts the string of names name1^name2^name3 into an array */
    nameSplitter(property) {
        return property.split('^', 3);
    }

    getEmptyStringForNull(value): string {
        return !value ? '' : value;
    }

    formatClassificationUnitName(classificationUnitCode: number) {
        return ' (CU ' + classificationUnitCode + ')';
    }

    getQueryId(route: ActivatedRoute): string {
      if (route && route.snapshot && route.snapshot.params && route.snapshot.params.id) {
        let value = route.snapshot.params.id;
        if (value !== null && value !== '') {
          value = value.trim();
          if (value.match(/^[0-9]+$/) != null) {
            return value;
          }
        }
      }
      return '';
    }

    deepCopy = <T>(target: T): T => {
        if (target === null) {
            return target;
        }
        if (target instanceof Date) {
            return new Date(target.getTime()) as any;
        }
        if (target instanceof Array) {
            const cp = [] as any[];
            (target as any[]).forEach((v) => { cp.push(v); });
            return cp.map((n: any) => this.deepCopy<any>(n)) as any;
        }
        if (typeof target === 'object') {
          const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
          Object.keys(cp).forEach(k => {
            cp[k] = this.deepCopy<any>(cp[k]);
          });
          return cp as T;
        }
        return target;
    }

    getAccountTypeByRouterUrl(routerUrl: string) { 
        const url = routerUrl !== null ? routerUrl.toLocaleLowerCase() : '';
        if (url.includes('restaurant')) {
            return AccountType.restaurant;
        }
        if (url.includes('retailer')) {
            return AccountType.retailer;
        }
        if (url.includes('homeowner')) {
            return AccountType.homeOwner;
        }
        if (url.includes('selfemployed') || url.includes('incorporated') || url.includes('corporation')) {
            return AccountType.other;
        }
        return null;
    }

    getFirmTypeByRouterUrl(routerUrl: string): FirmType {
        const url = routerUrl !== null ? routerUrl.toLocaleLowerCase() : '';
        if (url.includes('limitedcompany') || url.includes('incorporated') || url.includes('corporation')) {
            return FirmType.limitedCompany;
        }
        if (url.includes('partnership')) {
            return FirmType.partner;
        }
        if (url.includes('soleproprietorship')) {
            return FirmType.proprietor;
        }
        return null;
    }

    getRootUrlByRouterUrl(routerUrl: string) {
        const url = routerUrl !== null ? routerUrl.toLocaleLowerCase() : '';
        if (url.includes('restaurant/limitedcompany')) {
            return 'restaurant/limitedcompany';
        }
        if (url.includes('restaurant/partnership')) {
            return  'restaurant/partnership';
        }
        if (url.includes('restaurant/soleproprietorship')) {
            return  'restaurant/soleproprietorship';
        }
        if (url.includes('retailer/limitedcompany')) {
            return  'retailer/limitedcompany';
        }
        if (url.includes('retailer/partnership')) {
            return  'retailer/partnership';
        }
        if (url.includes('retailer/soleproprietorship')) {
            return  'retailer/soleproprietorship';
        }
        if (url.includes('selfemployed/soleproprietorship')) {
            return  'selfemployed/soleproprietorship';
        }
        if (url.includes('selfemployed/partnership')) {
            return  'selfemployed/partnership';
        }
        if (url.startsWith('/incorporated') || url.startsWith('incorporated')) {
            return 'incorporated';
        }
        if (url.startsWith('/selfemployed/partnership') || url.startsWith('selfemployed/partnership')) {
            return 'selfemployed/partnership';
        }
        if (url.startsWith('/selfemployed/soleproprietorship') || url.startsWith('selfemployed/soleproprietorship')) {
            return 'selfemployed/soleproprietorship';
        }  
        if (url.startsWith('/corporation') || url.startsWith('corporation')) {
            return 'corporation';
        }       
        return '';
    }

    getAccountTypeDescByRouterUrl(routerUrl: string) {
        const url = routerUrl !== null ? routerUrl.toLocaleLowerCase() : '';
        if (url.includes('restaurant')) {
            return 'restaurant';
        }
        if (url.includes('retailer')) {
            return 'retail store';
        }
        if (url.includes('corporation/contactinfo')) {
            return 'incorporated';
        }
        return '';
    }

    getRegistrationPaths(firmType: FirmType) {
        if (firmType === FirmType.partner) {
            return RegistrationPaths.Partnership.toString();
        }

        if (firmType === FirmType.proprietor) {
            return RegistrationPaths.SoleProprietorship.toString();
        }

        if (firmType === FirmType.limitedCompany) {
            return  RegistrationPaths.Incorporated.toString();
        }
        return '';
    }

    getFirmTypeDesc(firmType: FirmType) {
        if (firmType === FirmType.partner) {
            return 'partnership';
        }

        if (firmType === FirmType.proprietor) {
            return 'proprietorship';
        }

        if (firmType === FirmType.limitedCompany) {
            return 'firm';
        }
        return '';
    }

    combineAreaCodeAndPhoneNumber(phoneAreaCode: any, phoneNumber: any): string {
        return phoneNumber ? ( phoneAreaCode ? phoneAreaCode: '') + phoneNumber: '';
    }

    isMLEnabled(internetRegistration: any, path: string, sessionStorageService: SessionStorageService) {
        let enabled = false;
        let message = 'CommonFunctions-isMLEnabled path=' + path;
        try {
            const middleName= (internetRegistration !== null && internetRegistration.userChoice) ? internetRegistration.userChoice.contactNameMiddle : null;
            const testMiddleName = middleName != null && middleName =='testml123456789'? true: false;   
            message = message +' middleName='+ middleName + ' testMiddleName=' + testMiddleName;   

            const verifyPath = path!= null? path.toLowerCase() : '';
            let pathEnalbed = false;
            if (verifyPath.includes('corporation') || verifyPath.includes('incorporated')) {
                pathEnalbed = true;
            }
            message = message +' pathEnalbed='+ pathEnalbed;  
           
            let bFlagMLEnabled = this.getMLFlag(sessionStorageService);;

            enabled = (testMiddleName || bFlagMLEnabled) && pathEnalbed;       
            message = message +' isMLEnabled=' + enabled + ' bFlagMLEnabled=' + bFlagMLEnabled;  
        }
        catch(ex) {
            console.log('ex-'+ message + ex);
        }   
        console.log(message);
        return enabled;    
    }

    searchByML(internetRegistration: any, path: string, sessionStorageService: SessionStorageService) {
        // in case client side does not populate the flag into SessionStorage
        // if no MLToggleIndicator, server side will determine search source
        let searchByMLStr = '';
        let message = 'CommonFunctions-searchByML';
        try {
            const flagMLEnabledFound = sessionStorageService.getEnvironmentSettings(EnvironmentSettings.MLToggleIndicator);
            message = message +' ML Toggle Value (session_storage)=' + flagMLEnabledFound;  
            message = message + ' MLToggleIndicator: ' + flagMLEnabledFound != null ? ' found' : ' not found';             
            if (flagMLEnabledFound != null) {
               const bIsMLEnabled =  this.isMLEnabled(internetRegistration, path, sessionStorageService);
               searchByMLStr = bIsMLEnabled == true ? 'Y': 'N';
            } 
            message = message +' searchByMLStr=' + searchByMLStr;
        }
        catch(ex) {
            console.log('ex-'+ message + ex);
        }   
        console.log(message);
        return searchByMLStr;
    }
    
    getMLFlag(sessionStorageService: SessionStorageService) {
        let enabled = false;
        let message = 'CommonFunctions-isMLEnabled getMLFlag';
        try {
            const flagMLEnabled = sessionStorageService.getEnvironmentSettings(EnvironmentSettings.MLToggleIndicator);
            message = message +' ML Toggle Value (session_storage)=' + flagMLEnabled;          
            if (flagMLEnabled !=null && flagMLEnabled.toString().toLocaleLowerCase() =='true') {
                enabled = true;
            }             
            message = message +' bflagMLEnabled)=' + enabled;
        }
        catch(ex) {
            console.log('ex-'+ message + ex);
        }   
        console.log(message);
        return enabled;
    }   
    
  getPhoneAreaCode(value: number) {   
    let areaCode = '';
    if (value) {
      areaCode = value.toString();
      if(areaCode.length > 3) {
        areaCode = areaCode.slice(0, 3);
      }      
    }    
    return areaCode;
  }

  getPhoneNumber(value: number) {   
    let phoneNumber = '';    
    if (value) {
      phoneNumber = value.toString();   
      if (phoneNumber.length ==10) {
        phoneNumber = phoneNumber.slice(3, 10);  
      }      
    };
    return phoneNumber;
  }

  getVehiclesFromSessionStorage(sessionStorageService: SessionStorageService) {
    let vehicles = '';
    let message = 'CommonFunctions-getVehiclesFromSessionStorage';
    try {
        vehicles = sessionStorageService.getEnvironmentSettings(EnvironmentSettings.Vehicles);
        message = message +' vehicles (session_storage)=' + vehicles; 
    }
    catch(ex) {
        console.log('ex-'+ message + ex);
    }   
    console.log(message);
    return vehicles;
  }

  isVehicleClassificationUnit(sessionStorageService: SessionStorageService, classificationUnitCode: number) {
    let isVehicleCu = false;
    let message = 'CommonFunctions-isVehicleClassificationUnit';
    try {
        const vehicles = this.getVehiclesFromSessionStorage(sessionStorageService);
        if (classificationUnitCode >0 && vehicles && vehicles.includes(classificationUnitCode.toString())) {
            isVehicleCu = true;
        }
        message = 'CommonFunctions-isVehicleClassificationUnit =' + isVehicleCu;
    }
    catch(ex) {
        console.log('ex-'+ message + ex);
    }   
    console.log(message);
    return isVehicleCu;
  }  

    isSolePropEnabled(sessionStorageService: SessionStorageService) {
        let enabled = false;      
        let message = 'CommonFunctions-isSolePropEnabled';
        try {            
            const solePropEnabled = sessionStorageService.getUXEnvironmentSettingsSoleProp();
            message = message +' solePropEnabled Value (session_storage)=' + solePropEnabled;          
            if (solePropEnabled !=null && solePropEnabled.toString().toLocaleLowerCase() =='true') {
                enabled = true;
            }                     
            message = message +' solePropEnabled=' + enabled;
        }
        catch(ex) {
            console.log('ex-'+ message + ex);
        }         
        return enabled;   
    }

    setSolePropEnabled(solePropEnabled: boolean, sessionStorageService: SessionStorageService) {           
        let message = 'CommonFunctions-setSolePropEnabled solePropEnabled=' + solePropEnabled;
        try { 
            sessionStorageService.setUXEnvironmentSettingsSoleProp(solePropEnabled? 'true': 'false');    
        }
        catch(ex) {
            console.log('ex-'+ message + ex);
        }   
        console.log(message);
        return solePropEnabled;   
    }

    /*
  Keep same as [IREGN_CRLT_TYPE] to support IRV, IRU and EAS
  CRLTT_CD	CRLTT_NM
  a	Accountant
  bo	Board Officer
  ce	Company Employee
  fm	Family Member
  l	Lawyer
  o	Other
  p	Partner
  prop	Proprietor
  s	Spouse
  sh	Shareholder
  */
  getRelationshipData(firmType: FirmType, isUx20: boolean = false) {
    const otherTypeCode: string = 'o';
    let relationshipList = [];
    switch (firmType) {
      case FirmType.proprietor:
        if (isUx20) {
          relationshipList =   [
            { code: '', display: ' -- Select -- ', value: '' },
            { code: 'a', display: 'Accountant or bookkeeper', value: 'a' },
            { code: 's', display: 'Spouse', value: 's' },
            { code: 'fm', display: 'Other family member', value: 'fm' },           
            { code: 'l', display: 'Lawyer', value: 'l' },
            { code: 'ce', display: 'Employee', value: 'ce' },
            { code: 'o', display: 'Other', value: 'o' }
          ];
        } else {
          relationshipList =   [
            { code: '', display: ' -- Select -- ', value: '' },
            { code: 'a', display: 'Accountant', value: 'a' },
            { code: 'ce', display: 'Company employee', value: 'ce' },
            { code: 'fm', display: 'Family member', value: 'fm' },
            { code: 'prop', display: 'I am the proprietor', value: 'prop' },
            { code: 'l', display: 'Lawyer', value: 'l' },
            { code: 's', display: 'Spouse', value: 's' },
            { code: 'o', display: 'Other', value: 'o' }
          ];
        }        
        break;
      case FirmType.partner:
        relationshipList =   [
          { code: '', display: ' -- Select -- ', value: '' },
          { code: 'a', display: 'Accountant', value: 'a' },
          { code: 'ce', display: 'Company employee', value: 'ce' },
          { code: 'fm', display: 'Family member', value: 'fm' },
          { code: 'p', display: 'I am a partner', value: 'p'},
          { code: 'l', display: 'Lawyer', value: 'l' },
          { code: 's', display: 'Spouse', value: 's' },
          { code: 'o', display: 'Other', value: 'o' },
        ];
        break;
      case FirmType.limitedCompany:
        relationshipList =   [
            { code: '', display: ' -- Select -- ', value: '' },
            { code: 'sh', display: 'Owner or shareholder', value: OwnerOrShareholderTypeCode },
            { code: 'a', display: 'Accountant or bookkeeper', value: 'a' },
            { code: 'fm', display: 'Family member', value: 'fm' },
            { code: 'ce', display: 'Company employee', value: 'ce'},
            { code: 'o', display: 'Other', value: otherTypeCode },
          ];       
        break;
      case FirmType.association:
      case FirmType.band:
      case FirmType.church:
      case FirmType.cooperative:
      case FirmType.society:
      case FirmType.union:
        relationshipList =   [
          { code: '', display: ' -- Select -- ', value: '' },
          { code: 'a', display: 'Accountant', value: 'a' },
          { code: 'ce', display: 'Company employee', value: 'ce' },
          { code: 'fm', display: 'Family member', value: 'fm' },
          { code: 'l', display: 'Lawyer', value: 'l' },
          { code: 'sh', display: 'Shareholder', value: 'sh' },
          { code: 'o', display: 'Other', value: 'o' }
        ];
        break;
    }
    return relationshipList;
  }

  languageFilter(value: string): string {
    let result = value;
    try { 
        if (result) {
          //ç – the cedilla (la cédille)
          result = result.replace('ç', 'c');

          //é – the acute accent (l’accent aigu)
          result = result.replace('é', 'e');

          //â/ê/î/ô/û – the circumflex (l’accent circonflexe)
          result = result.replace('â', 'a');
          result = result.replace('ê', 'e');
          result = result.replace('î', 'i');
          result = result.replace('ô', 'o');
          result = result.replace('û', 'u');

          //à/è/ì/ò/ù – the grave accent (l’accent grave)
          result = result.replace('à', 'a');
          result = result.replace('è', 'e');
          result = result.replace('ì', 'i');
          result = result.replace('ò', 'o');
          result = result.replace('ù', 'u');

          //ë/ï/ü – the trema (l’accent tréma)
          result = result.replace('ë', 'e');
          result = result.replace('ï', 'i');
          result = result.replace('ü', 'u');

           // double 
           //ç – the cedilla (la cédille)
           result = result.replace("ç", 'c');

           //é – the acute accent (l’accent aigu)           
           result = result.replace("é", 'e');
 
           //â/ê/î/ô/û – the circumflex (l’accent circonflexe)
           result = result.replace("â", 'a');
           result = result.replace("ê", 'e');
           result = result.replace("î", 'i');
           result = result.replace("ô", 'o');
           result = result.replace("û", 'u');
 
           //à/è/ì/ò/ù – the grave accent (l’accent grave)
           result = result.replace("à", 'a');
           result = result.replace("è", 'e');
           result = result.replace("ì", 'i');
           result = result.replace("ò", 'o');
           result = result.replace("ù", 'u');
 
           //ë/ï/ü – the trema (l’accent tréma)
           result = result.replace("ë", 'e');
           result = result.replace("ï", 'i');
           result = result.replace("ü", 'u'); 

           // remove specail characters
           result = result.replace('.', '');   
           result = result.replace(".", ''); 
        }             
    } catch (ex) {
      console.log('CommonFunctions-languageFilter ex=' + ex.message);
    }    
    return result;
  }  
}
